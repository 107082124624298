import store from '../store';
import { USER_ROLE_TYPES } from '../common/constants';
export async function premiumPayment(to, from, next) {
  if (
    [
      'student-questions',
      'my-questions',
      'my-skills',
      'student-skill',
      'student-skill-details',
      'student-question-details',
      'student-attendance',
      'mark-attendance',
      'edit-attendance',
      'attendance-summary',
      'view-attendance'
    ].includes(to.name)
  ) {
    const payment = await store.dispatch('school/getSchoolPaymentStatus');

    if (!payment.data.premium_status) {
      if (store.getters['auth/getLoggedInUser'].role_type === USER_ROLE_TYPES.SCHOOL) {
        next({ name: 'upgrade-plan' });
      } else next({ name: 'student-home' });
    } else {
      next();
    }
  }
}
