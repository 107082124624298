import axios from 'axios';

const state = {
  currentSchool: null,
  paymentStatus: null,
  isAppPaymentStatus: null,
  isFacetsLinked: false,

  schoolsEmpFilters: {
    zipCode: null,
    zipCodeRange: 30
  },
  isFilterLoading: false
};

const getters = {
  getCurrentSchool: state => state.currentSchool,
  getPaymentStatus: state => state.paymentStatus,
  getInAppPaymentStatus: state => state.isAppPaymentStatus,
  getIsFacetsLinked: state => state.isFacetsLinked,
  getSchoolsEmpFilters: state => state.schoolsEmpFilters,
  getIsFilterLoading: state => state.isFilterLoading
};

const mutations = {
  updateCurrentSchool: (state, school) => {
    state.currentSchool = school;
  },
  updatePaymentStatus: (state, status) => {
    state.paymentStatus = status;
  },
  updateInAppPaymentStatus: (state, status) => {
    state.isAppPaymentStatus = status;
  },
  updateIsFacetsLinked: (state, status) => {
    state.isFacetsLinked = status;
  },
  updateSchoolsEmpFilters: (state, updatedFilters) => {
    state.schoolsEmpFilters = { ...state.schoolsEmpFilters, ...updatedFilters };
  },
  updateIsFilterLoading: (state, status) => {
    state.isFilterLoading = status;
  }
};

const actions = {
  async getAllSchools(_obj, params) {
    const res = await axios.get(`api/schools/`, { params });
    return res.data;
  },

  async getSchool(_obj, id) {
    const res = await axios.get(`api/schools/${id}/`);
    return res.data;
  },

  async createSchool(_obj, data) {
    return axios.post(`api/schools/`, data);
  },

  async updateSchool(_obj, { id, data }) {
    return axios.patch(`api/schools/${id}/`, data);
  },

  async deleteSchool(_obj, id) {
    return axios.delete(`api/schools/${id}/`);
  },

  async getSchoolImageUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/schools/image-upload-url/`, data)).data.data;
  },

  async getSchoolStudentCounts(_obj, queryData) {
    return (await axios.post(`api/schools/get-students-count/`, queryData)).data.data;
  },

  async getSchoolFromCurrentDomain({ commit, state }, { storeSchool = false }) {
    if (state.currentSchool) {
      return state.currentSchool;
    }
    if (window.location.host !== process.env.VUE_APP_MAIN_DOMAIN) {
      const res = await axios.get(`api/schools/get-domain-school/`, { params: { url: window.location.host } });
      if (storeSchool) {
        commit('updateCurrentSchool', res.data.data);
      }
      return res.data.data;
    }
    return null;
  },

  async getSchoolFromDomain({ commit }, params) {
    const res = await axios.get(`api/schools/get-domain-school/`, { params });
    commit('updateCurrentSchool', res.data.data);
    return res.data;
  },

  async getLocalJobsForCurrentSchool(_obj, params) {
    const res = await axios.get(`api/jobs/all/`, { params });
    return res.data;
  },
  async updateMySchoolProfile(_obj, data) {
    const resp = await axios.patch(`api/schools/update-school-profile/`, data);
    return resp;
  },
  async updateStudentDashboard(_obj, data) {
    const resp = await axios.patch(`api/schools/update-std-dashboard-config/`, data);
    return resp;
  },
  async getPartneredSchools(_obj, params) {
    const res = await axios.get(`api/schools/get-partnered-school/`, { params });
    return res.data;
  },
  async getSchoolConfigPiis(_obj, params) {
    const res = await axios.get(`api/schools/config-pii/`, { params });
    return res.data;
  },
  async updateSclConfigPiis(_obj, data) {
    const resp = await axios.patch(`/api/schools/config-pii/update-config-piis/`, data);
    return resp;
  },

  async updateSclConfigPiisLabel(_obj, { id, data }) {
    const resp = await axios.patch(`/api/schools/config-pii/${id}/update-pii-title/`, data);
    return resp;
  },

  async getSchoolPaymentPlans(_obj, params) {
    const res = await axios.get(`api/schools/payment-plans/ `, { params });
    return res.data;
  },
  async getSchoolPaymentStatus({ commit }, params) {
    const res = await axios.get(`api/schools/payment-plan-status/`, { params });
    commit('updatePaymentStatus', res.data.data.premium_status);
    return res.data;
  },
  async updateMetaTags(_obj, { id, data }) {
    return axios.patch(`api/schools/${id}/update-meta-tags/`, data);
  },

  async getAllResources(_obj, params) {
    const res = await axios.get(`api/schools/resource/`, { params });
    return res.data;
  },

  async getResource(_obj, id) {
    const res = await axios.get(`api/schools/resource/${id}/`);
    return res.data;
  },

  async createResource(_obj, data) {
    return axios.post(`api/schools/resource/`, data);
  },

  async updateResource(_obj, { id, data }) {
    return axios.patch(`api/schools/resource/${id}/`, data);
  },

  async deleteResource(_obj, id) {
    return axios.delete(`api/schools/resource/${id}/`);
  },

  async getResourceFromSlug(_obj, params) {
    const res = await axios.get(`api/schools/resource/school-resource-from-slug/`, { params });
    return res.data;
  },
  async getSchoolFromReffId(_obj, params) {
    const res = await axios.get(`api/schools/unique-school/`, { params });
    return res.data;
  },

  async getCNASchoolPaymentPlans() {
    const res = await axios.get(`api/schools/healthenroll-payment-plans/`);
    return res.data;
  },

  async getAllOrganizationSchools(_obj, params) {
    const res = await axios.get(`api/schools/training-provider/`, { params });
    return res.data;
  },

  async getinAppPaymentPlan({ commit }) {
    if (state.isAppPaymentStatus) {
      return state.isAppPaymentStatus;
    }
    const res = await axios.get(`api/schools/school-inapp-payment/`);
    commit('updateInAppPaymentStatus', res.data.data.inapp_pymnt_enable);
    return res.data.data.inapp_pymnt_enable;
  },

  async getAllSponsorSchools(_obj, params) {
    const res = await axios.get(`api/schools/scholarship-schools/`, { params });
    return res.data;
  },

  async linkFacets({ commit }, data) {
    let res;
    try {
      res = await axios.post(`api/schools/link-facets/`, data);

      commit('updateIsFacetsLinked', true);
    } catch (error) {
      commit('updateIsFacetsLinked', false);
    }
    return res;
  },

  async getFacetsVerification({ commit }) {
    const res = await axios.get(`api/schools/facets-verification/`);

    commit('updateIsFacetsLinked', res.data.data.facets_key_available);
    return res.data;
  },

  async getFacetsUrl() {
    const res = await axios.get(`api/schools/facets-url/`);
    return res.data;
  },

  async getSchoolDemoStdAccount(_obj, params) {
    const res = await axios.get(`api/schools/std-demo-creds/`, { params });
    return res.data;
  },
  async getDashboard(_obj, params) {
    const res = await axios.get(`api/schools/dashboard/`, { params });
    return res.data;
  },

  async updateSchoolsEmpFilters({ commit }, { filters }) {
    commit('updateSchoolsEmpFilters', filters);
  },

  async getAllInterestedSchools(_obj, params) {
    const res = await axios.get(`api/schools/signup-request/`, { params });
    return res.data;
  },

  async getInterestedSchool(_obj, id) {
    const res = await axios.get(`api/schools/signup-request/${id}/`);
    return res.data;
  },

  async updateInterestedSchoolStatus(_obj, { id, data }) {
    const res = await axios.patch(`api/schools/signup-request/${id}/`, data);
    return res.data;
  },

  async schoolSignUp(_obj, data) {
    return axios.post(`api/schools/signup-request/`, data);
  },

  async getSchoolSignupImageUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/schools/signup-request/image-upload-url/`, data)).data.data;
  },

  async createInterestedSchool(_obj, data) {
    return axios.post(`api/schools/create-interested-school/`, data);
  },

  async getBestSchoolInfo() {
    const res = await axios.get(`api/schools/best-school-info/`);
    return res.data;
  },

  async updateBestSchoolInfo(_obj, data) {
    const resp = await axios.patch(`api/schools/update-best-school-info/`, data);
    return resp;
  },

  async requestDemo(_obj, data) {
    const resp = await axios.patch(`api/schools/update-demo-request/`, data);
    return resp;
  },

  async getAllDocs(_obj, params) {
    const res = await axios.get(`api/schools/config-pii/titles/`, { params });
    return res.data;
  },
  async getStudentPiiConfig(_obj, params) {
    const res = await axios.get(`api/schools/config-pii/program-required-docs/`, { params });
    return res.data;
  },
  async getAllTags(_obj, params) {
    const res = await axios.get(`api/schools/all-tags/`, { params });
    return res.data;
  }
};

export const school = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
