import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getSkillVideo(_obj, data) {
    const res = await axios.post(`api/skills/fetch-skill-video-ask-url/`, data);
    return res.data;
  },
  async updateSkillVideoPrivacy(_obj, { id, data }) {
    const res = await axios.patch(`/api/skills/${id}/update-student-skill-privacy/`, data);
    return res.data;
  },
  async getSkills(_obj, params) {
    const res = await axios.get(`api/skills/`, { params });
    return res.data;
  },
  async getSkill(_obj, id) {
    const res = await axios.get(`api/skills/${id}/`);
    return res.data;
  },
  async deleteSkill(_obj, id) {
    const res = await axios.delete(`api/skills/${id}/`);
    return res.data;
  },
  async getSkillTitle(_obj, params) {
    const res = await axios.get(`api/skills/unique-skill-title/`, { params });
    return res.data;
  },
  async getStudentSkills(_obj, params) {
    const res = await axios.get(`api/skills/student-skills/`, { params });
    return res.data;
  },
  async updateApprovalSkill(_obj, { id, data }) {
    const res = await axios.patch(`api/skills/${id}/update-approval-status/`, data);
    return res.data;
  }
};

export const skills = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
