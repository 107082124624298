import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllUnis(_obj, params) {
    const res = await axios.get(`api/universities/`, { params });
    return res.data;
  },

  async getUni(_obj, id) {
    const res = await axios.get(`api/universities/${id}/`);
    return res.data;
  },

  async createUni(_obj, data) {
    return axios.post(`api/universities/`, data);
  },

  async updateUni(_obj, { id, data }) {
    return axios.patch(`api/universities/${id}/`, data);
  },

  async deleteUni(_obj, id) {
    return axios.delete(`api/universities/${id}/`);
  },

  async getUniImageUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/universities/image-upload-url/`, data)).data.data;
  },

  async getAllPartneredUniversities(_obj, params) {
    const res = await axios.get(`api/universities/school-partnered-universities/`, { params });
    return res.data;
  },

  async getAllUniversitiesForStd(_obj, params) {
    const res = await axios.get(`api/universities/student-portal-universities/`, { params });
    return res.data;
  },
  async getAllUniversitiesByState(_obj, params) {
    const res = await axios.get(`api/universities/public-university-list/`, { params });

    return res.data;
  }
};

export const uni = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
