import { get, last, map } from 'lodash';
import moment from 'moment';
import languageNameMap from 'language-name-map/map';

import { sanitizeUrl } from '@braintree/sanitize-url';

/**
 * Parse file name from the given url of an uploaded file.
 * @param {string} url File url.
 * @param {boolean} removeTimestamp Wheather or not to remove the timestamp from start of the file name.
 * @returns Parsed file name.
 */
export function parseFileNameFromUrl(url, removeTimestamp = false) {
  let fileName = decodeURI(last(url.split('/')));
  if (removeTimestamp) fileName = fileName.replace(/^[0-9.]+_/, '');
  return fileName;
}

export function formatFullDate(date) {
  return moment(date).format('MMMM DD, YYYY');
}
export function formatDateTime(dateTime) {
  return moment(dateTime).format('MMM DD, YYYY, hh:mm A');
}
export function formatDocsDateTime(dateTime) {
  return moment(dateTime).format('MM/DD/YY  hh:mm a');
}
export function formatTimeAgo(dateTime) {
  return moment(dateTime).fromNow();
}
export function formatTime(dateTime) {
  return moment(dateTime).fromNow(true);
}
export function formatDateSimple(date) {
  return moment(date).format('MM/DD/YY');
}
export function formatToAPIDate(date) {
  return moment(date).format('YYYY-MM-DD');
}

export function isDateExpired(expiresAt) {
  return moment(expiresAt).isSameOrBefore(formatToAPIDate(moment()));
}

export function getLangOptions(nameKey = 'name') {
  return Object.keys(languageNameMap).map(key => ({ value: key, [nameKey]: languageNameMap[key].name }));
}

export function getClickableLink(link) {
  return link.startsWith('http://') || link.startsWith('https://') ? sanitizeUrl(link) : sanitizeUrl(`https://${link}`);
}

export function getClickableLinkUsSanitize(link) {
  return link.startsWith('http://') || link.startsWith('https://') ? link : '';
}

export const NOTIFICATION_EXTRA_MAPPINGS = (action, notification) => {
  return {
    emp_scl_partnership_requested: [notification.extra.request_from_name],
    emp_scl_partnership_accepted: [notification.extra.request_from_name],
    testimonial_posted: [get(notification, 'notifier.first_name') + ' ' + get(notification, 'notifier.last_name')],
    signup_request_posted: [get(notification, 'notifier.first_name') + ' ' + get(notification, 'notifier.last_name')],
    partnership_rec_fee_changed: [notification.extra.school_name, notification.extra.new_rec_fee],
    partnership_rec_fee_changed_free: [notification.extra.school_name],
    partnership_ref_fee_changed: [notification.extra.school_name, notification.extra.new_ref_fee],
    successfully_hired: [notification.extra.employer_name],
    token_purchased: [notification.extra.tokens],
    std_profile_unlocked: [notification.extra.employer_name],
    org_std_profile_unlocked: [notification.extra.org_name],
    user_commented: [
      get(notification, 'notifier.first_name') + ' ' + get(notification, 'notifier.last_name'),
      get(notification, 'extra.comment_on_type', 'skill')
    ],
    user_replied: [
      get(notification, 'notifier.first_name') + ' ' + get(notification, 'notifier.last_name'),
      get(notification, 'extra.comment_on_type', 'skill')
    ],
    feedback_posted: [get(notification, 'notifier.first_name') + ' ' + get(notification, 'notifier.last_name')],
    feedback_replied: [get(notification, 'notifier.first_name') + ' ' + get(notification, 'notifier.last_name')],
    status_approved: [get(notification, 'notifier.first_name') + ' ' + get(notification, 'notifier.last_name')],
    post_liked: [get(notification, 'notifier.first_name') + ' ' + get(notification, 'notifier.last_name')],
    school_credited: [
      get(notification, 'extra.credits_earned', 1),
      get(notification, 'extra.student_name', ''),
      get(notification, 'extra.employer_name', 'an employer')
    ],
    org_credits_deduct: [get(notification, 'extra.employer_name'), get(notification, 'extra.credits_used')],
    org_token_deduct: [get(notification, 'extra.tokens_used'), get(notification, 'extra.employer_name')],
    signup_org_employer: [notification.extra.employer_name],
    app_feature_update: [notification.extra.notification_title],
    new_signup_request: [notification.extra.school_name],
    school_demo_request: [notification.extra.school_name],
    simulation_submission: [notification.extra.student_name, notification.extra.simualtion_name]
  }[action];
};

export function titleize(value) {
  return value.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
}

export function getHumanReadableCommaSepStr(arr, lastSep = 'and') {
  return [arr.slice(0, -1).join(', '), arr.slice(-1)[0]].join(arr.length < 2 ? '' : ` ${lastSep} `);
}
export function capitalizeFirstLetter(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}
export function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
}

// export function stripHtmlTags(value) {
//   const noTags = (value || '').replace(/(<([^>]+)>)/gi, '');
//   return (noTags + '').replace(/&#\d+;/gm, s => String.fromCharCode((s.match(/\d+/gm) || [])[0]));
// }

export function stripHtmlTags(value) {
  const noTags = (value || '').replace(/(<([^>]+)>)/gi, '');
  return (noTags + '')
    .replaceAll('&nbsp;', '')
    .replace(/&#\d+;/gm, s => String.fromCharCode((s.match(/\d+/gm) || [])[0]));
}

export function getWorkTravelOptions() {
  const options = map([...Array(9).keys()], num => ({
    value: `${(num + 1) * 10}`,
    text: `${(num + 1) * 10} miles`
  }));
  options.push({ value: '100+', text: '100+ miles' });
  return options;
}

export function computeAddress(data, keys, stateKey = 'state', zipcodeKey = 'zipcode') {
  let address = '';
  keys.forEach(item => {
    address = address
      .concat(
        item === zipcodeKey && data[stateKey] && data[zipcodeKey] ? ' ' : data[item] && address !== '' ? `, ` : ''
      )
      .concat(data[item] ? data[item] : '');
  });
  return address;
}
