import axios from 'axios';
import { DEFAULT_FAVICON } from '../../common/constants';
import { get } from 'lodash';

const state = {
  accessToken: '',
  refreshToken: '',
  user: null,
  status: null,
  piiVerificationToken: null
};

const getters = {
  isLoggedIn: state => {
    return !!state.accessToken && !!state.user;
  },
  getAuthToken: state => state.accessToken,
  getRefreshToken: state => state.refreshToken,
  getLoggedInUser: state => state.user,
  getPiiVerificationToken: state => state.piiVerificationToken
};

const mutations = {
  login: (state, res) => {
    state.accessToken = res.accessToken;
    state.refreshToken = res.refreshToken;
    state.user = res.user;
  },
  logout: state => {
    state.accessToken = '';
    state.refreshToken = '';
    state.piiVerificationToken = null;
    state.user = null;
  },
  updateMyProfile: (state, updatedUser) => {
    state.user = { ...state.user, ...updatedUser };
  },
  updatePiiVerificationToken: (state, token) => {
    state.piiVerificationToken = token;
  }
};

const actions = {
  async login({ commit }, data) {
    const loginResp = (
      await axios.post('api/token/', {
        email: data.email,
        password: data.password
      })
    ).data;

    // Store in state.
    commit('login', {
      accessToken: loginResp.access,
      refreshToken: loginResp.refresh,
      user: loginResp.user
    });

    return loginResp;
  },

  async logout({ commit, dispatch, rootState }) {
    dispatch('navbarConfig/resetNavbarBrandDetails', {}, { root: true });
    commit('student/updateMyStudentProfile', null, { root: true });
    commit('stories/updateStoryExists', {}, { root: true });
    commit('school/updateInAppPaymentStatus', null, { root: true });
    commit('logout');

    let currSchool = null;

    // Reset app colors after logout.
    if (window.location.host === process.env.VUE_APP_MAIN_DOMAIN) {
      commit('school/updateCurrentSchool', currSchool, { root: true });
    } else {
      currSchool = rootState.school.currentSchool;
    }

    document.documentElement.style.setProperty('--primary-color', get(currSchool, 'primary_color', '#00b3e6'));
    document.documentElement.style.setProperty('--secondary-color', get(currSchool, 'secondary_color', '#1ca085'));
    document.documentElement.style.setProperty('--dark-color', get(currSchool, 'dark_color', '#214e6f'));
    document.getElementById('favicon').href = get(currSchool, 'small_logo_url') || DEFAULT_FAVICON;
  },

  async getMe() {
    const res = await axios.get(`api/users/me/`);
    return res.data.data;
  },

  async updateMyProfile({ commit }, data) {
    await axios.patch(`api/users/update-profile/`, data);

    commit('updateMyProfile', data);
  },

  async updateMyPassword(_obj, data) {
    await axios.patch(`api/users/update-password/`, data);
  },

  async getUserAvatarPresignedUrl(_obj, data) {
    return (await axios.post(`api/users/avatar-upload-url/`, data)).data.data;
  },
  async validateUser({ commit }, data) {
    const res = await axios.post(`api/users/validate-user/`, data);
    commit('updatePiiVerificationToken', res.data.data.token);
    return res;
  },
  async resetPassword(_obj, data) {
    return axios.post(`api/password-reset/`, data);
  },

  async resetPasswordConfirm(_obj, data) {
    return axios.post(`api/password-reset/confirm/`, data);
  },

  async validateResetPasswordToken(_obj, data) {
    return axios.post(`api/password-reset/validate_token/`, data);
  },

  updateResetPasswordTokenInvalid({ commit }, tokenInvalid) {
    commit('updatePassResetTokenInvalid', tokenInvalid);
  }
};

export const auth = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
